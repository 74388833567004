export const enum inventoryTypes {
  NFT = 'NFT',
  RESOURCE = 'RESOURCE',
  RELIC = 'RELIC',
  PLOT = 'PLOT',
  ROBOT = 'ROBOT',
  CRIB = 'CRIB',
  THIRD_PARTY = 'THIRD_PARTY',
  CRAFT = 'CRAFT',
  FOUNDER_KEY = 'FOUNDER_KEY',
  GUARDIAN = 'GUARDIAN',
  NITRO = 'NITRO'
}

export const enum craftTypes {
  RELICS = 'RELICS',
  FURNITURE = 'FURNITURE',
  LIGHTING = 'LIGHTING',
  FLOORING = 'FLOORING',
  WEAPONS = 'WEAPONS',
  THEME = 'THEME',
  ELECTRONICS = 'ELECTRONICS',
  FLORA = 'FLORA'
}
