/* eslint-disable  @typescript-eslint/no-floating-promises  */
/* eslint-disable   @typescript-eslint/strict-boolean-expressions  */
/* eslint-disable @typescript-eslint/restrict-plus-operands  */
import {
  collection,
  doc,
  getDocs,
  addDoc,
  onSnapshot,
  setDoc,
  query,
  limit,
  orderBy,
  where,
  getDoc,
  DocumentData,
  writeBatch
} from 'firebase/firestore'
import Firebase from '../Firebase'

import {
  WALLET_TYPE,
  IRewardStatus,
  TRANSACTION_STATUS,
  ICardanoZoneConfigs
} from '../../types/commonTypes'
import { Unsubscribe } from 'firebase/auth'
import { InventorySnapshotCallback } from '../../components/inventory/interface'
import { ISnapshotInput } from '../../configs/types'
import { IReward } from '../../components/claims/interface'
import { IPlot } from './../../components/land-management/interface'
import { INotificationListeners } from '../../components/notifications/types'
import configs from '../../configs/config'
import { inventoryTypes } from '../../components/inventory/constants'
// import { AppDispatch } from '../../store/store'
// import { updateRewardsModifiedItem } from '../../data/actions/reduxDataActions'

export const createUserIfNotExist = async (walletAddress: string): Promise<boolean> => {
  try {
    const profileCollection = collection(Firebase.getInstance(), configs.f_user_collection_path)

    const profileDoc = doc(profileCollection, walletAddress)
    const docData = {
      walletAddress
    }

    await setDoc(profileDoc, docData, { merge: true })
    return true
  } catch (error) {}

  return false
}

export const createWalletDocToWallets = async (
  walletAddress: string,
  stakeAddress: string,
  walletType: string,
  userID: string,
  blockchain: string
): Promise<boolean> => {
  try {
    const docData = {
      is_default: false,
      is_linked: false,
      is_synced: false,
      stake_address: stakeAddress?.toLowerCase(),
      wallet_address: walletAddress?.toLowerCase(),
      wallet_blockchain: blockchain.toUpperCase(),
      wallet_type: walletType.toUpperCase(),
      sso_user_id: userID
    }
    const docRef = doc(Firebase.getInstance(), 'wallets', walletAddress.toLowerCase())
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const walletRef = collection(Firebase.getInstance(), 'wallets')
      await setDoc(
        doc(walletRef, walletAddress),
        {
          is_synced: false,
          stake_address: stakeAddress?.toLowerCase(),
          wallet_blockchain: blockchain,
          wallet_address: walletAddress?.toLowerCase(),
          wallet_type: walletType.toUpperCase(),
          sso_user_id: userID
        },
        { merge: true }
      )
    } else {
      await setDoc(doc(Firebase.getInstance(), 'wallets', walletAddress.toLowerCase()), docData, {
        merge: true
      })
    }
    return true
  } catch (error) {
    return false
  }
}
export const updateTxHashStatus = async (
  docId: string,
  walletAddress: string,
  txHash: string,
  userID: string,
  status: string,
  contractAddress: string
): Promise<boolean> => {
  try {
    const txCollection = collection(Firebase.getInstance(), '/eth_transaction_track')

    const txDoc = doc(txCollection, txHash.toLowerCase())
    const docData = {
      tx_hash: txHash,
      user_id: userID,
      ref_id: docId,
      status,
      created_at: new Date().getTime(),
      wallet_address: walletAddress.toLowerCase(),
      contract_address: contractAddress.toLowerCase()
    }
    await setDoc(txDoc, docData, { merge: true })
    return true
  } catch (error) {
  }

  return false
}
export const updateRewardMintStatus = async (
  docId: string,
  rewardID: string,
  status: IRewardStatus,
  walletAddress: string,
  walletAddressLowercase: string,
  txHash: string,
  userID: string
): Promise<boolean> => {
  try {
    const rewardCollection = collection(Firebase.getInstance(), configs.f_reward_collection_path)

    const rewardDoc = doc(rewardCollection, docId)

    const currentReward = await getDoc(rewardDoc)

    const reward = currentReward.data() as IReward
    const claimedAt = new Date().getTime()
    if (
      reward.claimable_user?.toLowerCase() === walletAddressLowercase ||
      reward?.user_id === userID
    ) {
      const docData = {
        status,
        tx_hash: txHash,
        claimed_at: claimedAt
      }
      await setDoc(rewardDoc, docData, { merge: true })
      return true
    }
    return false
  } catch (error) {
  }

  return false
}

const getInventoryResourceItem = (
  resName: string,
  qty: number,
  image: string,
  description: string,
  userID: string | ''
): any => {
  const res: any = {}
  res.contract_address = ''
  res.description = description ?? ''
  res.brand_address = ''
  res.brand_name = ''
  res.token_id = 0
  res.user_id = userID
  res.mintable = false
  res.is_minted = false
  res.blockchain = ''
  res.project_id = ''
  res.name = resName
  res.inventory_type = 'RESOURCE'
  res.qty = qty
  res.updated_at = new Date().getDate()
  res.image = image
  return res
}

export const markNotificationRead = async (docID: string): Promise<void> => {
  try {
    const notificcationCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/notifications/'
    )
    await setDoc(doc(notificcationCollection, docID), { is_read: true }, { merge: true })
  } catch (error) {
    console.log('markNotificationRead', error)
  }
}

export const recahrgeBotFromNotification = async (
  botID: any,
  plotDocID: any,
  notifcationDocId: any
): Promise<void> => {
  const inventoryCollection = collection(Firebase.getInstance(), configs.f_inventory_path)
  const batch = writeBatch(Firebase.getInstance())
  const currentPlotSnap = await getDoc(doc(inventoryCollection, plotDocID))
  if (currentPlotSnap?.exists()) {
    const currentPlot = currentPlotSnap.data()
    let isPlotUpdated = false
    for (const bot of currentPlot?.bots ?? []) {
      if (bot.token_id === botID) {
        bot.claim_count = 0
        bot.power = 100
        isPlotUpdated = true
      }
    }
    if (isPlotUpdated) {
      batch.set(doc(inventoryCollection, plotDocID), { bots: currentPlot.bots }, { merge: true })
    }
  }
  const notificcationCollection = collection(
    Firebase.getInstance(),
    '/virtua_resource_portal/users_data/notifications/'
  )

  batch.set(doc(notificcationCollection, notifcationDocId), { is_read: true }, { merge: true })
  await batch.commit()
}

const updateHistory = async (
  resourceObject: any,
  userID: string | null | undefined,
  resoruceInventoryData?: any
): Promise<boolean> => {
  try {
    const historyCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/history/'
    )
    let historyObject
    if (resoruceInventoryData) {
      historyObject = {
        ...resoruceInventoryData,
        ...resourceObject,
        user_id: userID,
        claimed_at: Date.now(),
        category: resourceObject.inventory_type ?? 'RESOURCE'
      }
    } else {
      historyObject = {
        ...resourceObject,
        user_id: userID,
        claimed_at: Date.now(),
        category: resourceObject.inventory_type ?? 'RESOURCE'
      }
    }
    delete historyObject.inventory_type
    await setDoc(doc(historyCollection), historyObject, { merge: true })
    return true
  } catch (error) {
    console.log('updateHistory', error)
  }
  return true
}

export const addUserQuest = async (userQuest: any): Promise<boolean> => {
  try {
    const batch = writeBatch(Firebase.getInstance())
    const usersQuestCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/user_quests'
    )
    const inventoryCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/inventory'
    )
    addDoc(usersQuestCollection, userQuest)
      .then(async (docRef) => {
        for (const item of userQuest.attachments) {
          batch.set(
            doc(inventoryCollection, item.doc_id),
            {
              is_attached: true,
              attached_ref: 'QUEST',
              attached_ref_id: docRef.id
            },
            { merge: true }
          )
        }
        await batch.commit()
      })
      .catch((err) => {
        console.log(err)
      })
    return true
  } catch (error) {
    console.log('addUserQuest', error)
    return false
  }
}

export const addUserCraft = async (userId: string | null, userCraft: any, requirements: any, craftDocId: string, craftQty: number, craftSupplyCount: number): Promise<boolean> => {
  try {
    const batch = writeBatch(Firebase.getInstance())
    const usersQuestCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/user_craftings'
    )
    const inventoryCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/inventory'
    )
    const assetCraftCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/assets/craftables'
    )
    addDoc(usersQuestCollection, userCraft)
      .then(async (docRef) => {
        if (userCraft.attachments.length > 0) {
          for (const item of userCraft.attachments) {
            batch.set(
              doc(inventoryCollection, item.doc_id),
              {
                is_attached: true,
                attached_ref: 'CRAFT',
                attached_ref_id: docRef.id
              },
              { merge: true }
            )
          }
        }
        for (const resource of requirements) {
          const docId = `${userId}_${resource.code}`
          const currentResourceInventoryItem = await getDoc(doc(inventoryCollection, docId))
          const resourceInventoryData = currentResourceInventoryItem.data()
          const resourceDocRef = doc(inventoryCollection, docId)
          if (resourceInventoryData?.qty - (resource.qty * craftQty) < 0) {
            return false
          }
          if (resourceInventoryData) {
            batch.update(
              resourceDocRef,
              { qty: resourceInventoryData.qty - (resource.qty * craftQty) }
            )
          }
        }
        const assetCraftItem = await getDoc(doc(assetCraftCollection, craftDocId))
        const assetCraftData = assetCraftItem.data()
        const resourceDocRef = doc(assetCraftCollection, craftDocId)
        if (assetCraftData) {
          batch.update(
            resourceDocRef,
            { total_crafted: assetCraftData.total_crafted + craftQty }
          )
        }
        if (craftDocId && craftSupplyCount > 0) {
          if (assetCraftData) {
            batch.update(
              resourceDocRef,
              { supply_count: assetCraftData.supply_count - craftQty }
            )
          }
        }

        await batch.commit()
      })
      .catch((err) => {
        console.log(err)
      })
    return true
  } catch (error) {
    console.log('addUserCraft', error)
    return false
  }
}

const getResourceDocIDForOwner = (userID: string | null, tempResourceName: string): string => {
  return userID + '_' + tempResourceName.trim().replace(' ', '_')
}

export const claimResource = async (
  resourceToClaimName: string,
  userID?: string | null
): Promise<boolean> => {
  try {
    const resourceCollection = collection(Firebase.getInstance(), configs.f_inventory_path)
    // const firestoreResources: any = []
    const plotsSnapShot = await getDocs(
      query(
        resourceCollection,
        where('inventory_type', '==', 'PLOT'),
        where('user_id', '==', userID)
      )
    )
    const userPlots: any[] | undefined = plotsSnapShot?.docs?.map((item) => ({
      ...item.data(),
      docID: item.id
    }))
    const inventoryBatch = writeBatch(Firebase.getInstance())

    let currentResourceQty = 0
    let currentResourceDescription = ''
    let currentResouceImage = '' // TO Create new ResourceIn Inventory
    if (userPlots?.length > 0) {
      for (const plotItem of userPlots) {
        let isPlotUpdated = false
        plotItem.temp_storage?.forEach((resource: any) => {
          if (resource.name === resourceToClaimName) {
            currentResourceQty = currentResourceQty + resource.qty
            resource.qty = 0
            resource.claim_by = 'MANUAL'
            resource.last_claim_date = new Date().getTime()
            isPlotUpdated = true
            currentResouceImage = resource.image ?? ''
            currentResourceDescription = resource.description ?? ''
          }
        })
        if (isPlotUpdated) {
          inventoryBatch.set(
            doc(resourceCollection, plotItem.docID),
            { temp_storage: plotItem.temp_storage },
            { merge: true }
          )
        }
      }
    }
    const resoruceDocID = getResourceDocIDForOwner(
      userID ?? null,
      resourceToClaimName
    ).toLowerCase()
    const currentResourceInvetoryItem = await getDoc(doc(resourceCollection, resoruceDocID))
    if (currentResourceInvetoryItem?.exists()) {
      const resoruceInventoryData = currentResourceInvetoryItem.data()
      const objecToUpdate: any = {}
      objecToUpdate.updated_at = new Date().getTime()
      objecToUpdate.claim_by = 'MANUAL'
      objecToUpdate.qty = currentResourceQty
      objecToUpdate.description = currentResourceDescription
      objecToUpdate.user_id = userID
      await updateHistory(objecToUpdate, userID, resoruceInventoryData)
      objecToUpdate.qty = resoruceInventoryData.qty + currentResourceQty
      inventoryBatch.set(doc(resourceCollection, resoruceDocID), objecToUpdate, { merge: true })
    } else {
      const invetnyRes = getInventoryResourceItem(
        resourceToClaimName,
        currentResourceQty,
        currentResouceImage,
        currentResourceDescription,
        userID ?? ''
      )
      inventoryBatch.set(doc(resourceCollection, resoruceDocID), invetnyRes, { merge: true })
      await updateHistory(invetnyRes, userID)
    }
    // INVENTORY ALL TRANSACTIONS COMMIT
    await inventoryBatch.commit()

    // get all dacuemtns for same resounce set to read true, if claim scussfully
    const notificcationCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/notifications/'
    )
    const notifcationsQry = query(
      notificcationCollection,
      where('resource', '==', resourceToClaimName),
      where('receiver', '==', userID),
      where('is_read', '==', false)
    )
    const data = await getDocs(notifcationsQry)
    // notifications ALL TRANSACTIONS COMMIT
    const chunkSize = 300
    const notifcaitosnDocs = data?.docs
    for (let i = 0; i < notifcaitosnDocs?.length; i += chunkSize) {
      const chunk = notifcaitosnDocs?.slice(i, i + chunkSize)
      const notificationsBatch = writeBatch(Firebase.getInstance())
      chunk?.forEach((item) => {
        notificationsBatch.set(
          doc(notificcationCollection, item.id),
          { is_read: true },
          { merge: true }
        )
      })
      await notificationsBatch.commit()
    }

    return true
  } catch (error) {
    console.log('updateResourceStatus', error)
  }

  return false
}

export const claimResourceGeneric = async (item: any): Promise<boolean> => {
  const docId = `${item.user_id}_${item.name}`
  const resourceCollection = collection(Firebase.getInstance(), configs.f_inventory_path)
  const assetsCollection = collection(Firebase.getInstance(), configs.f_assets_rewards_path)
  const currentResourceInventoryItem = await getDoc(doc(resourceCollection, docId))
  if (currentResourceInventoryItem?.exists()) {
    const resourceInventoryData = currentResourceInventoryItem.data()
    await setDoc(
      doc(resourceCollection, docId),
      {
        qty: resourceInventoryData.qty + item.qty
      },
      { merge: true }
    )
  } else {
    const resource = await getDoc(doc(assetsCollection, item.name))
    await setDoc(
      doc(resourceCollection, docId),
      {
        ...resource.data(),
        inventory_type: inventoryTypes.RESOURCE,
        qty: item.qty,
        user_id: item.user_id,
        token_id: 0
      },
      { merge: true }
    )
  }
  return true
}

export const claimResourceFromQuest = async (
  ReservedRewards: any,
  userID: string | null,
  questID: string
): Promise<boolean> => {
  try {
    const resourceCollection = collection(Firebase.getInstance(), configs.f_inventory_path)
    const userQuestsCollection = collection(
      Firebase.getInstance(),
      '/virtua_resource_portal/users_data/user_quests'
    )
    const batch = writeBatch(Firebase.getInstance())

    batch.set(
      doc(userQuestsCollection, questID),
      {
        is_claimed: true
      },
      { merge: true }
    )

    for (const item of ReservedRewards) {
      const resourceDocID = getResourceDocIDForOwner(userID ?? null, item.name).toLowerCase()
      const currentResourceInventoryItem = await getDoc(doc(resourceCollection, resourceDocID))
      if (currentResourceInventoryItem?.exists()) {
        const resourceInventoryData = currentResourceInventoryItem.data()
        batch.set(
          doc(resourceCollection, resourceDocID),
          {
            qty: resourceInventoryData.qty + item.qty
          },
          { merge: true }
        )
      } else {
        batch.set(
          doc(resourceCollection, resourceDocID),
          {
            ...item,
            user_id: userID
          },
          { merge: true }
        )
      }
    }
    await batch.commit()
    return true
  } catch (error) {
    console.log('updateResourceStatus', error)
  }
  return false
}

export const setupRewardsSnapshotListener = (
  walletAddressLowercase: string,
  listener: (rewardItem: IReward) => void
): Unsubscribe => {
  const rewardCollection = collection(Firebase.getInstance(), configs.f_reward_collection_path)
  const q = query(rewardCollection, where('claimable_user', '==', walletAddressLowercase))
  return onSnapshot(q, (snapshot) => {
    snapshot?.docChanges().forEach((change) => {
      const changedReward = change.doc.data() as IReward
      changedReward.docID = change.doc.id
      listener(changedReward)
    })
  })
}

// export const setupSesaonItemsStatusListener = (
//   userID: string,
//   dispatch: AppDispatch
// ): Unsubscribe => {
//   const seasonLevel = ''
//   const rewardCollection = collection(
//     Firebase.getInstance(),
//     `/profile/${userID}/seasonal_tracks/${seasonLevel}/levels_rewards`
//   )
//   const q = query(rewardCollection)
//   return onSnapshot(q, (snapshot) => {
//     snapshot?.docChanges().forEach((change) => {
//       const modifiedItem = change.doc.data()
//       modifiedItem.docID = change.doc.id
//       if (change.type === 'modified') {
//         setTimeout(() => {
//           dispatch(updateRewardsModifiedItem(modifiedItem))
//         }, 0)
//       }
//     })
//   })
// }

export const setupRewardsStatusListener = (
  userID: string,
  listener: INotificationListeners
): Unsubscribe => {
  const rewardCollection = collection(Firebase.getInstance(), configs.f_reward_collection_path)
  const q = query(
    rewardCollection,
    where('user_id', '==', userID),
    where('status', '==', 'PROCESSING'),
    where('tx_hash', '!=', '')
  )
  return onSnapshot(q, (snapshot) => {
    snapshot?.docChanges().forEach((change) => {
      const modifiedPlot = change.doc.data() as IReward
      modifiedPlot.docID = change.doc.id
      if (change.type === 'added') {
        listener.addProcessingItem(modifiedPlot)
      } else if (change.type === 'removed') {
        getByPathAndDocId(configs.f_reward_collection_path, change.doc.id)
          .then((docRemoved: any) => {
            if (docRemoved.status === TRANSACTION_STATUS.AVAILABLE) {
              listener.addFailedItem(docRemoved)
            } else if (docRemoved.status === TRANSACTION_STATUS.CLAIMED) {
              listener.addMintedItem(docRemoved)
            }
          })
          .catch(() => {})
      }
    })
  })
}
export const getByPath = async <Type>(path: string): Promise<Type | Error> => {
  try {
    const address = `${configs.f_base_path}${path}`
    const profileCollection = collection(Firebase.getInstance(), address)
    const data = await getDocs(profileCollection)
    const result = data.docs.map((item) => item.data())
    return result as Type
  } catch (error) {
    console.log('Error:', error)
    throw new Error('something went wrong')
  }
}

export const getByPathAndDocId = async <Type>(
  path: string,
  docId: string
): Promise<Type | Error> => {
  try {
    const profileCollection = collection(Firebase.getInstance(), path)
    const profileDocRef = doc(profileCollection, docId)
    const profileDoc = await getDoc(profileDocRef)
    const result = { ...profileDoc.data(), docID: profileDoc.id }
    return result as Type
  } catch (error) {
    console.log('Error:', error)
    throw new Error('something went wrong')
  }
}
export const getSeasonLevelsDataQuery = async <Type>(seasonID: number): Promise<Type | Error> => {
  try {
    const seasonalTracksCollectionDoc = collection(Firebase.getInstance(), configs.f_assets_rewards_path)

    const seasonDocsQry = await getDocs(query(
      seasonalTracksCollectionDoc,
      where(
        'season_id',
        '==', seasonID
      ), where(
        'total_supply', '>', 0
      )))
    const results = seasonDocsQry.docs?.map((item) => ({ ...item.data(), docID: item.id, locked_status: 'LOCKED' }))
    return results as Type
  } catch (error) {
    console.log('Error:', error)
    throw new Error('something went wrong')
  }
  // try {
  //   const seasonalTracksCollectionDoc = collection(Firebase.getInstance(), path)
  //   const seasonDocsQry = await getDocs(seasonalTracksCollectionDoc)
  //   const results = seasonDocsQry.docs?.map((item) => ({ ...item.data(), docID: item.id }))
  //   return results as Type
  // } catch (error) {
  //   console.log('Error:', error)
  //   throw new Error('something went wrong')
  // }
}
export const getSeasonDefaultsLevelsDataQuery = async <Type>(seasonID: number): Promise<Type | Error> => {
  try {
    const seasonalTracksCollectionDoc = collection(Firebase.getInstance(), configs.f_assets_rewards_path)

    const seasonDocsQry = await getDocs(query(
      seasonalTracksCollectionDoc,
      where(
        'season_id',
        '==', seasonID
      ), where(
        'total_supply', '>', 0
      )))
    const results = seasonDocsQry.docs?.map((item) => ({ ...item.data(), docID: item.id, locked_status: 'LOCKED' }))
    return results as Type
  } catch (error) {
    console.log('Error:', error)
    throw new Error('something went wrong')
  }
}
export const getSeasonRewards = async <Type>(seasonID: number): Promise<Type | Error> => {
  try {
    const seasonalRewardsCollectionDoc = collection(Firebase.getInstance(), configs.f_assets_rewards_path)
    const seasonDocsQry = await getDocs(query(
      seasonalRewardsCollectionDoc,
      where(
        'type',
        '==', 'WELCOME_REWARD'
      )
      // where(
      //   'type',
      //   '==', seasonID
      // ), where(
      //   'level', '==', 1
      // )))
    ))
    const results = seasonDocsQry.docs?.map((item) => ({ ...item.data(), docID: item.id }))
    return results as Type
  } catch (error) {
    throw new Error('something went wrong')
  }
}
export const getDocByPathAndID = async <Type>(
  path: string,
  docID: string
): Promise<Type | Error> => {
  try {
    const seasonalTracksCollectionDoc = doc(collection(Firebase.getInstance(), `${path}`), docID)
    const seasonDoc = await getDoc(seasonalTracksCollectionDoc)
    const result = seasonDoc?.data() ?? null
    return result as Type
  } catch (error) {
    console.log('Error:', error)
    throw new Error('something went wrong')
  }
}
export const getSeasonConfig = async <Type>(season: string): Promise<Type | Error> => {
  try {
    const seasonalTracksCollectionDoc = doc(
      collection(Firebase.getInstance(), `${configs.f_base_path}config/seasons`),
      season
    )
    const seasonDoc = await getDoc(seasonalTracksCollectionDoc)
    const result = seasonDoc?.data() ?? null
    return result as Type
  } catch (error) {
    console.log('Error:', error)
    throw new Error('something went wrong')
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getByPathWhere = async (data: ISnapshotInput) => {
  const address = `${configs.f_base_path}${data.path}`
  try {
    if ('where' in data) {
      const whereObj: any[] = []
      const limitObj: any[] = []
      const orderByObj: any[] = []
      data.where?.forEach((condition) => {
        whereObj.push(where(condition.key, condition.op, condition.value))
      })
      if (data.Limit && data.OrderBy) {
        limitObj.push(limit(data.Limit))
        orderByObj.push(orderBy(data.OrderBy))
      }
      const queryObj = query(
        collection(Firebase.getInstance(), address),
        ...whereObj,
        ...limitObj,
        ...orderByObj
      )
      const res = await getDocs(queryObj)
      const result = res.docs.map((item) => ({ ...item.data(), docID: item.id }))
      return result
    }
  } catch (error) {
    console.log(error)
    throw new Error('something went wrong')
  }
}
export const getSnapshotByPath = (
  data: ISnapshotInput,
  listener: InventorySnapshotCallback
): Unsubscribe => {
  const address = `${configs.f_base_path}${data.path}`
  try {
    if ('where' in data) {
      const whereObj: any[] = []
      const limitObj: any[] = []
      const orderByObj: any[] = []
      data.where?.forEach((condition) => {
        whereObj.push(where(condition.key, condition.op, condition.value))
      })
      if (data.Limit && data.OrderBy) {
        limitObj.push(limit(data.Limit))
        orderByObj.push(orderBy(data.OrderBy))
      }
      const queryObj = query(
        collection(Firebase.getInstance(), address),
        ...whereObj,
        ...limitObj,
        ...orderByObj
      )
      return onSnapshot(queryObj, listener)
    } else {
      const queryObj = collection(Firebase.getInstance(), address)
      return onSnapshot(queryObj, listener)
    }
  } catch (error) {
    console.log(error)
    throw new Error('something went wrong')
  }
}

export const addDocTXStatusListener = (
  collectionDocPath: string,
  listener: (state: TRANSACTION_STATUS | null) => void
): Unsubscribe => {
  const docRef = doc(Firebase.getInstance(), collectionDocPath)
  return onSnapshot(docRef, (snapShot: DocumentData) => {
    listener(snapShot?.data?.()?.status ?? null)
  })
}

export const updatePlotsConfigurations = async (
  docId: string,
  plotItem: any,
  userId: string | null
): Promise<boolean> => {
  const path = configs.f_inventory_path
  const invetoryCollection = collection(Firebase.getInstance(), path)
  const invetoryPlotItemDoc = doc(invetoryCollection, docId)
  const plotInvtoryItemQuery = await getDoc(invetoryPlotItemDoc)

  const plotInvtoryItem = plotInvtoryItemQuery.data() as any
  if (userId !== null && plotInvtoryItem.user_id === userId) {
    const docData = {
      bots: plotItem.bots
      // crib: plotItem.crib ?? null
    }
    await setDoc(invetoryPlotItemDoc, docData, { merge: true })
    return true
  }
  return true
}

export const updateInventoryBot = async (
  docId: string,
  userID: string | null,
  docData: { attached_ref: string | null, attached_ref_id: number | null, is_attached: boolean }
): Promise<boolean> => {
  try {
    const path = configs.f_inventory_path
    const invetoryCollection = collection(Firebase.getInstance(), path)
    // if (!docData.is_attached) {
    //   const notifications = await getNotifications(userID)
    // }
    const invetoryBotItemDoc = doc(invetoryCollection, docId)
    await setDoc(invetoryBotItemDoc, docData, { merge: true })
    return true
  } catch (error) {
    console.log('updateRewardStatus', error)
  }

  return false
}
export const updateInventoryBotPower = async (plot: IPlot): Promise<boolean> => {
  try {
    const path = configs.f_inventory_path
    const invetoryCollection = collection(Firebase.getInstance(), path)

    const invetoryBotItemDoc = doc(invetoryCollection, plot.docId)

    await setDoc(invetoryBotItemDoc, { bots: plot.bots }, { merge: true })
    return true
  } catch (error) {
    console.log('updateRewardStatus', error)
  }

  return false
}

export const getMetaverseConfigs = async (): Promise<ICardanoZoneConfigs | null> => {
  try {
    const cardanoConfigs = doc(Firebase.getInstance(), configs.cardano_claim_configs)
    const cardanoConfigsDoc = await getDoc(cardanoConfigs)
    return cardanoConfigsDoc.data() as ICardanoZoneConfigs
  } catch (ignore) {}
  return null
}

export const updateUserIDInventory = async (
  userID: string,
  walletAddress: string,
  walletBlockChain: string,
  cardanoStakeAddress?: string | null
): Promise<boolean> => {
  try {
    const resourceCollection = collection(Firebase.getInstance(), configs.f_inventory_path)
    const whereQ =
      walletBlockChain === WALLET_TYPE.CARDANO
        ? where('stake_address', '==', cardanoStakeAddress?.toLowerCase())
        : where('owner_id', '==', walletAddress?.toLowerCase())

    const walletInvetorySnap = await getDocs(
      query(
        resourceCollection,
        whereQ
        // where('user_id', 'in', ['0', 0, null, '', undefined]) TODO this query
      )
    )
    const inventoryBatch = writeBatch(Firebase.getInstance())
    let batchSize = 0
    walletInvetorySnap?.docs?.forEach((item) => {
      // User can sync again by pressing sync , for now batch size is 400
      if (batchSize < 400) {
        if (
          item.data().user_id === '0' ||
          item.data().user_id === 0 ||
          item.data().user_id === undefined ||
          item.data().user_id === null
        ) {
          batchSize++
          inventoryBatch.set(
            doc(resourceCollection, item.id),
            { user_id: userID.toLowerCase() },
            { merge: true }
          )
        }
      }
    })
    if (batchSize > 0) {
      await inventoryBatch.commit()
    }
  } catch (error) {
    console.log('updateResourceStatus', error)
  }
  return false
}

export const updateUserRewards = async (
  userID: string,
  walletAddress: string,
  walletBlockChain: string,
  cardanoStakeAddress?: string | null
): Promise<boolean> => {
  try {
    const rwardsCollection = collection(Firebase.getInstance(), configs.f_reward_collection_path)
    const walletInvetorySnap = await getDocs(
      query(
        rwardsCollection,
        where(
          'claimable_user',
          '==',
          walletBlockChain === WALLET_TYPE.CARDANO
            ? cardanoStakeAddress?.toLowerCase()
            : walletAddress.toLowerCase()
        )
      )
    )
    const rewardsBatch = writeBatch(Firebase.getInstance())
    let batchSize = 0
    walletInvetorySnap?.docs?.forEach((item) => {
      if (
        item.data().user_id === '0' ||
        item.data().user_id === 0 ||
        item.data().user_id === undefined ||
        item.data().user_id === null
      ) {
        batchSize++
        rewardsBatch.set(
          doc(rwardsCollection, item.id),
          { user_id: userID.toLowerCase() },
          { merge: true }
        )
      }
    })
    if (batchSize > 0) {
      await rewardsBatch.commit()
    }
  } catch (error) {
    console.log('updateResourceStatus', error)
  }
  return false
}

export const updateByPath = async (
  path: string,
  docId: string,
  updateObj: any
): Promise<boolean> => {
  try {
    const notificationCollection = collection(
      Firebase.getInstance(),
      `${configs.f_base_path}${path}`
    )
    const notificationDocRef = doc(notificationCollection, docId)
    await setDoc(notificationDocRef, updateObj, { merge: true })
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}

export const updateByFullPath = async (
  path: string,
  docId: string,
  updateObj: any
): Promise<boolean> => {
  try {
    const notificationCollection = collection(Firebase.getInstance(), path)
    const notificationDocRef = doc(notificationCollection, docId)
    await setDoc(notificationDocRef, updateObj, { merge: true })
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}
export const setErrorLogs = async (data: any): Promise<void> => {
  try {
    const errorLogsCollection = collection(Firebase.getInstance(), '/virtua_resource_portal/logs/error-logs')

    const logRef = doc(errorLogsCollection)
    const docData = {
      ...data
    }

    await setDoc(logRef, docData, { merge: true })
  } catch (error) {}
}
