/* eslint-disable n/handle-callback-err */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from 'react'
import { setErrorLogs } from '../../firebase/actions/firestoreActions'

export class ErrorBoundary extends Component {
  constructor (props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    // console.log(error)
    return { hasError: true }
  }

  componentDidCatch (error: any, errorInfo: any) {
    const errorDoc = {
      src: 'PRIME_DASHBOARD_FRONTEND',
      errorMsg: error?.toString(),
      errorInfo: JSON.stringify(errorInfo),
      data: Date.now()
    }
    setErrorLogs(errorDoc)
      .then(() => {})
      .catch(() => {})
  }

  render () {
    const { hasError }: any = this.state
    const { children }: any = this.props
    if (hasError === true) {
      return <div role="alert" style={{ border: '1px solid white', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
        <p>Something went wrong:</p>
        <div style={{ color: 'red' }}>{'Please refresh your browser'}</div>
        </div>
      {/* <div>{error?.message ?? ''}</div> */}
    </div>
    }

    return children
  }
}
