import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResource } from '../../components/temporary-storage/interface'

interface IResources {
  Resources: IResource[]
  Plots: any
}

export interface IResourceState {
  Resources: IResource[]
  Plots: []
}

const initialState: IResources = {
  Resources: [],
  Plots: []
}

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setResource: (state, action: PayloadAction<IResource>) => {
      state.Resources.push(action.payload)
    },
    setPlots: (state, action: PayloadAction<IResource>) => {
      state.Plots.push(action.payload)
    },
    removeResources: (state, action: PayloadAction) => {
      state.Resources.length = 0
    },
    removePlots: (state, action: PayloadAction) => {
      state.Plots.length = 0
    }
  }
})

export const { setResource, setPlots, removeResources, removePlots } = resourceSlice.actions

export default resourceSlice
