import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import 'swiper/css/pagination'
import 'swiper/css'
import './index.scss'
import { Provider } from 'react-redux'
import store from './store/store'
import { LoadingComponent } from './components/common/Loading'
import { ErrorBoundary } from './components/error-boundary'
import { ToastContainer } from 'react-toastify'
import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from './configs/wagmiConfig'

/* eslint-disable */
const App = lazy(() => import('./App'))
const WalletProvider = lazy(() => import('./wallets/provider/WalletProvider'))
const AuthProvider = lazy(() => import('./authentication/AuthProvider'))
const ProviderMessagesHandler = lazy(() => import('./providers/ProviderMessagesHandler'))
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
  <WagmiConfig config={wagmiConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <Suspense fallback={<LoadingComponent />}>
          <WalletProvider>
            <ProviderMessagesHandler>
              <AuthProvider>
                <>
                  <App />
                  <ToastContainer style={{zIndex:"99999"}} />
                </>
              </AuthProvider>
            </ProviderMessagesHandler>
          </WalletProvider>
        </Suspense>
      </Provider>
    </ErrorBoundary>
    </WagmiConfig>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
