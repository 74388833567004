import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserProfile } from '../../types/commonTypes'
export type TProfileLoadStatus =
  | 'SUCCESS'
  | 'FAILED'
  | 'LOADING'
  | 'IDLE'
  | 'TIMEOUT'
export type WALLET_TYPE = 'ETH' | 'CARDANO'
const WALLET_ADDRESS_KEY = '__wa'
const WALLET_ADDRESS_STAKE_KEY = '__wa_stake'
const WALLET_ADDRESS_TYPE_KEY = '__wa_t'
export interface IUserState {
  profile: IUserProfile | null
  walletAddress?: string | null
  cardanoStakeAddress?: string | null
  walletType?: WALLET_TYPE | null
  cardanoWalletName?: string | null
  isLoadingProfile: boolean
  profileStatus: TProfileLoadStatus
  message?: string
}
const initialState: IUserState = {
  profile: null,
  walletAddress: localStorage.getItem(WALLET_ADDRESS_KEY),
  cardanoStakeAddress: localStorage.getItem(WALLET_ADDRESS_STAKE_KEY),
  walletType: localStorage.getItem(WALLET_ADDRESS_TYPE_KEY) !== null ? localStorage.getItem(WALLET_ADDRESS_TYPE_KEY) as WALLET_TYPE : null,
  isLoadingProfile: false,
  profileStatus: 'IDLE'
}

const updateWalletLocalStorage = (walletType: WALLET_TYPE | null, walletAddress: string | null, stakeAddress?: string | null): void => {
  walletAddress !== null && walletAddress !== undefined
    ? localStorage.setItem(
      WALLET_ADDRESS_KEY,
      walletAddress ?? ''
    )
    : localStorage.removeItem(WALLET_ADDRESS_KEY)

  stakeAddress !== null && stakeAddress !== undefined
    ? localStorage.setItem(
      WALLET_ADDRESS_STAKE_KEY,
      stakeAddress ?? ''
    )
    : localStorage.removeItem(WALLET_ADDRESS_STAKE_KEY)

  walletType !== null && walletType !== undefined
    ? localStorage.setItem(
      WALLET_ADDRESS_TYPE_KEY,
      walletType ?? ''
    )
    : localStorage.removeItem(WALLET_ADDRESS_TYPE_KEY)
}
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoadingProfile: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProfile = action.payload
    },
    setWalletAddress: (state, action: PayloadAction<{ walletType: WALLET_TYPE | null, walletAddress: string | null, stakeAddress?: string | null, cardanoWalletName?: string | null } >) => {
      state.walletAddress = action.payload.walletAddress
      state.walletType = action.payload.walletType
      state.cardanoStakeAddress = action.payload?.stakeAddress
      state.cardanoWalletName = action.payload?.cardanoWalletName
      updateWalletLocalStorage(action.payload.walletType, action.payload.walletAddress, action.payload.stakeAddress)
    },
    setUserProfile: (state, action: PayloadAction<IUserState | null>) => {
      if (action.payload !== null) {
        const newState = { ...state, ...action.payload }
        state.profile = newState.profile ?? null
        state.walletAddress = newState.walletAddress
        state.cardanoStakeAddress = newState.cardanoStakeAddress
        state.walletType = newState.walletType
        state.cardanoWalletName = newState.cardanoWalletName
        state.isLoadingProfile = newState.isLoadingProfile ?? false
        state.profileStatus = newState.profileStatus ?? 'IDLE'
        state.message = newState.message ?? undefined
        updateWalletLocalStorage(newState.walletType ?? null, newState.walletAddress ?? null, newState.cardanoStakeAddress ?? null)
      } else {
        state.profile = null
        state.walletAddress = null
        state.cardanoStakeAddress = null
        state.walletType = null
        state.cardanoWalletName = null
        state.isLoadingProfile = false
        state.profileStatus = 'IDLE'
        state.message = undefined
        updateWalletLocalStorage(null, null, null)
      }
    }
  }
})

export const { setIsLoadingProfile, setUserProfile, setWalletAddress } = userSlice.actions

export default userSlice
