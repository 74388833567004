import React from 'react'
import './styles.scss'

export const Loader = (): JSX.Element => {
  return <div className='loader'><img src={`${process.env.REACT_APP_S3_IMGS_URL}tv_loader.gif`} alt="" /></div>
}

export const LoadingComponent = (): JSX.Element => {
  return <div className="loadingContainer">
    <Loader />
  </div>
}

export const SectionLoader = (): JSX.Element => {
  return <div className="sc-loader">
    <Loader />
  </div>
}
