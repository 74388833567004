/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBalancePayload, IConvertionRatePayload, ISeasonInfo, IUserRankAndBadgesPayload } from '../../types/commonTypes'

export interface IState {
  balancePointsInfo: IBalancePayload | null
  convertionRateinfo: IConvertionRatePayload | null
  isLoading: boolean
  userGlobalRankAndBadges: IUserRankAndBadgesPayload | null
  seasonData: ISeasonInfo | null
}

const initialState: IState = {
  balancePointsInfo: null,
  convertionRateinfo: null,
  isLoading: true,
  userGlobalRankAndBadges: null,
  seasonData: null
}

export const balanceSlice = createSlice({
  name: 'balancePointsInfo',
  initialState,
  reducers: {
    setBalanceInfoAction: (state, action: PayloadAction<IBalancePayload>) => {
      state.balancePointsInfo = action.payload
    },
    setBalanceLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setConvertionRateAction: (state, action: PayloadAction<IConvertionRatePayload>) => {
      state.convertionRateinfo = action.payload
    },
    setUserGlobalRankBadgesAction: (state, action: PayloadAction<IUserRankAndBadgesPayload>) => {
      state.userGlobalRankAndBadges = action.payload
    },
    setSeasonInfo: (state, action: PayloadAction<ISeasonInfo>) => {
      state.seasonData = action.payload
    }
  }
})

export const { setConvertionRateAction, setBalanceInfoAction, setBalanceLoading, setUserGlobalRankBadgesAction, setSeasonInfo } = balanceSlice.actions

export default balanceSlice
