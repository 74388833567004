import { IBotPlot, IPlot } from '../components/land-management/interface'

export interface IUserProfile {
  email?: string | null
  refreshToken?: string | null
  accessToken?: string
  firestoreToken?: string
  firestoreRefreshToken?: string
  firestoreCustomToken?: string
  firstName?: string
  lastName?: string
  id?: string | null
  username?: string | null
  attributes?: Record<string, string[]>
}
export interface IBalancePayload {
  xp: number
  vcoins: number
  pp: number
  seasonal_xp: number
  user_track?: string
  levels: {
    total: number
    current: number
    percentage: number
  }
  level_progress: {
    current_level: number
    next_level: number
    seasonal_xp: number
    next_level_xp: number
    percentage: number
  }
  season: {
    id: number
    total_xps_count: number
    percentage: number
  }
}
export interface IConvertionRatePayload {
  xp: number
  rate: number
}
export interface IBadges {
  name: string
  icon: string
  description: string
  _id: string
}
export interface IUserRankAndBadgesPayload {
  user_rank: number
  badges: IBadges[]
}
export interface ISeasonInfo {
  end_time: string
  id: number
  name: string
  start_time: string
  total_levels_count: number
  total_xps_count: number
  bucket: string
}
export interface IPrestigePointsBundlePayload {
  id: number
  name: number
  points: number
}
export interface IConvertPPtoXPsPayload {
  xp: number
  rate: number
  current_pp: number
  current_xp?: number
}

export enum TRANSACTION_STATUS {
  PROCESSING = 'PROCESSING',
  AVAILABLE = 'AVAILABLE',
  CLAIMED = 'CLAIMED'
}
export enum CLAIM_STATUS {
  PROCESSING = 'PROCESSING',
  AVAILABLE = 'AVAILABLE',
  CLAIMED = 'CLAIMED'
}
export enum E_INVENTORY_TYPE {
  PLOT = 'PLOT',
  CRIB = 'CRIB',
  ROBOT = 'ROBOT'
}
export enum WALLET_TYPE {
  ETH = 'ETH',
  CARDANO = 'CARDANO'
}
export enum SIGNATURE_TYPES {
  FD_FK_RM = 'FD_FK_RM',
  FD_FK_LB = 'FD_FK_LB',
  FD_FG_RM = 'FD_FG_RM',
  FD_FG_WL = 'FD_FG_WL'
}
export enum WALLET_BLOCKCHAIN {
  ETH = 'ETH',
  CARDANO = 'CARDANO'
}
export enum CLAIM_ITEM_CATEGORY_TYPE {
  FOUNDER_KEY = 'FOUNDER_KEY',
  GUARDIAN = 'GUARDIAN'
}
export enum NOTIFCATION_TYPES {
  RESOURCE_CLAIM = 'RESOURCE_CLAIM',
  BOT_POWER_DOWN = 'BOT_POWER_DOWN',
  RESOURCE_CLAIM_STICKEY = 'RESOURCE_CLAIM_STICKEY',
  RESOURCE_CLAIM_MAXED_OUT = 'RESOURCE_CLAIM_MAXED_OUT',
  CRAFT_COMPLETED = 'CRAFT_COMPLETED'
}
export type IRewardStatus = TRANSACTION_STATUS

export interface IAppConfigs {
  f_api_key: string
  f_app_id: string
  f_auth_domain: string
  f_measure_id: string
  f_message_sender_id: string
  f_project_id: string
  f_storage_bucket: string
  search_api_key: string
}

export interface ISavePlotConfigsAction {
  plot: IPlot
  // modifiedBots: IBotPlot[]
  modifiedBots: any[]
  userID: string | null
}
// eslint-disable-next-line
export interface IModifiedBots {
  [token_id: string]: IBotPlot
}

export interface ICardanoZoneConfigs {
  metaverse_url: string
  is_crib_live: boolean
}
