import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type THEME = 'LIGHT' | 'DARK'
export type TConfigLoadStatus =
  | 'SUCCESS'
  | 'FAILED'
  | 'LOADING'
  | 'IDLE'

export interface CommonRXState {
  theme: THEME
  appConfigs: string | null
  configStatus: TConfigLoadStatus

}
const initialState: CommonRXState = {
  theme: 'LIGHT',
  appConfigs: null,
  configStatus: 'IDLE'
}
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<THEME>) => {
      state.theme = action.payload
    },
    setConfig: (state, action: PayloadAction<string | null>) => {
      state.appConfigs = action.payload
      state.configStatus = action.payload !== null && action.payload !== undefined ? 'SUCCESS' : 'IDLE'
    },

    setConfigStatus: (state, action: PayloadAction<TConfigLoadStatus>) => {
      state.configStatus = action.payload
    }
  }
})

export const { setTheme, setConfig, setConfigStatus } = commonSlice.actions

export default commonSlice
