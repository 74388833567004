import { configureStore } from '@reduxjs/toolkit'
import commonSlice from './slices/commonSlice'
import mintingSlice from './slices/mintingSlice'
import userSlice from './slices/userSlice'
import resourceSlice from './slices/resourceSlice'
import balanceSlice from './slices/ledgerBalanceSlice'
import seasonSlice from './slices/seasonSlice'
const store = configureStore({
  reducer: {
    commonState: commonSlice.reducer,
    userProfile: userSlice.reducer,
    mintingState: mintingSlice.reducer,
    resourceState: resourceSlice.reducer,
    balanceState: balanceSlice.reducer,
    seasonState: seasonSlice.reducer
  }
})
export type AppDispatch = typeof store.dispatch
export type TRootState = ReturnType<typeof store.getState>

export default store
