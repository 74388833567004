import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TMintingStatus = 'SUCCESS' | 'FAILED' | 'MINTING' | 'IDLE'
export interface IMintingState {
  claimMintingStatus: TMintingStatus
  claimMintingMessage: string | null
  txHash: string | null
  cta?: string | null
}
const initialState: IMintingState = {
  claimMintingStatus: 'IDLE',
  claimMintingMessage: null,
  txHash: null,
  cta: null
}
const mintingSlice = createSlice({
  name: 'minting',
  initialState,
  reducers: {
    setClaimMintingStatus: (state, action: PayloadAction<TMintingStatus>): void => {
      state.claimMintingStatus = action.payload
    },
    setClaimMintingResponse: (state, action: PayloadAction<IMintingState>): void => {
      state.claimMintingStatus = action.payload.claimMintingStatus
      state.claimMintingMessage = action.payload.claimMintingMessage
      state.txHash = action.payload.txHash
      state.cta = action.payload.cta
    }
  }
})

export const { setClaimMintingStatus, setClaimMintingResponse } = mintingSlice.actions
export default mintingSlice
