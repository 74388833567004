import {
  Auth,
  getAuth,
  NextOrObserver,
  Unsubscribe,
  User
} from 'firebase/auth'
import { Database } from 'firebase/database'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Firestore, getFirestore } from 'firebase/firestore'
import { IAppConfigs } from '../types/commonTypes'

/* eslint-disable @typescript-eslint/no-extraneous-class */

class Firebase {
  private static firebaseInstance: Firestore | null = null
  private static firebaseApp: FirebaseApp | null = null
  private static readonly firebaseDBInstance: Database | null = null
  private constructor () {}

  private static readonly setupFirebase = (configs: IAppConfigs): void => {
    if (Firebase.firebaseInstance == null) {
      const firebaseConfig = {
        apiKey: configs.f_api_key,
        authDomain: configs.f_auth_domain,
        projectId: configs.f_project_id,
        storageBucket: configs.f_storage_bucket,
        messagingSenderId: configs.f_message_sender_id,
        appId: configs.f_app_id,
        measurementId: configs.f_measure_id
      }

      const app = initializeApp(firebaseConfig)
      Firebase.firebaseApp = app
      Firebase.firebaseInstance = getFirestore(app)
    }
  }

  public static isFirestoreConnected = (): boolean => {
    return Firebase.firebaseInstance !== null
  }

  public static getInstance = (): Firestore => {
    // if (Firebase.firebaseInstance == null) {
    //   Firebase.setupFirebase()
    // }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Firebase.firebaseInstance!
  }

  public static getFirebaseAuth = (): Auth => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getAuth(Firebase.firebaseApp!)
  }

  public static setupFirebaseAuthStateChangeListener = (
    configs: IAppConfigs,
    listener: NextOrObserver<User>
  ): Unsubscribe => {
    if (Firebase.firebaseInstance == null) {
      Firebase.setupFirebase(configs)
    }
    return Firebase.getFirebaseAuth().onIdTokenChanged(listener)
  }

  public static logoutUser = (): void => {
    try {
      Firebase.getFirebaseAuth().signOut().then(() => {}).catch(() => {})
    } catch (ignore) {
    }
  }
}

export default Firebase
