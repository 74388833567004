import { createConfig, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { mainnet, goerli, polygonMumbai } from 'wagmi/chains'
const supportedChains = []
const envChains = JSON.parse((process.env.REACT_APP_CHAIN_ID) ?? '1')

if (envChains.includes(1) === true) {
  supportedChains.push(mainnet)
}
if (envChains.includes(5) === true) {
  supportedChains.push(goerli)
}
if (envChains.includes(80001) === true) {
  supportedChains.push(polygonMumbai)
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  supportedChains,
  [publicProvider()]
)

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '4719d3708f4c5626f9717ce01b88ff71'
      }
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Virtua'
      }
    })
  ],
  publicClient,
  webSocketPublicClient
})
