import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISeasonLevelData } from '../../types/commonInterfaces'

export interface ISeasonState {
  rewardsResponce: ISeasonLevelData | null
}
const initialState: ISeasonState = {
  rewardsResponce: null

}
const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    setSeasonRewardsResponce: (state, action: PayloadAction<ISeasonLevelData>): void => {
      state.rewardsResponce = action.payload
    }
  }
})

export const { setSeasonRewardsResponce } = seasonSlice.actions
export default seasonSlice
